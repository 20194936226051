import React from "react"
import { Layout } from "../components/Layout"
import {
  SectionDivider,
  SectionDividerMobile,
  ServiceSection,
  DemoButton,
  LearnMoreButton,
  GetStartedButton,
  CountsSection,
  Testimonials,
  Faq,
  AutoScrollBanner,
  TryIrisGPTForFreeBanner,
  FeatureSection,
  FeatureSectionRight
} from "../commonComponents"

import { Helmet } from "react-helmet"

export default function SupportAgentAssist() {
  return (
    <Layout
      keywords="ai agent assist,  agent assist bot, agent assist ai, agent assist chatbot, support agent assist, chatgpt, generative ai,"
      title="GPT-powered AI Agent Assist Chatbot:  Way to faster Ticket resolutions"
      description="Enhance customer support with our powerful AI agent-assist chatbot solution, powered by GPT. Improve CSAT & boost productivity with the latest customer support AI"
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        {" "}
        <link
          rel="canonical"
          href="https://irisagent.com/support-agent-assist/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div
            className="col-md-6 section-padding-left"
            style={{ paddingLeft: "0" }}
          >
            <h1 className="title-support-new-design">
              <span className="text-highlight">Solve 40% of support queries</span> with IrisGPT Chatbot and Agent Assist
            </h1>
            <div className="description-support-new-design web-view-new-design-display">
              Enterprise-grade Generative AI trained securely on <br/>your articles and tickets to deflect user queries and <br/> assist agents
            </div>
            <div className="description-support-new-design mobile-view-new-design-display">
              Enterprise-grade Generative AI trained securely on <br/>your articles and tickets to deflect user queries and <br/> assist agents
            </div>
            <br />
            <div className="description-button-support-new-design">
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6 centered-image-wrapper" >
              <img
                src="/img/irisgpt.svg"
                alt="support agent"
                style={{ width: "92%"}}
              />
            </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

{/*  ======= Cta Section =======  */}
      <TryIrisGPTForFreeBanner />
      {/*  End Cta Section  */}
          <br/><br/>
          <FeatureSection
            title="Resolve common issues quickly with AI"
            description="With models fine-tuned on your article and ticket data, IrisGPT provides recommended resolution at your fingertips. Enable your team with fast resolutions and automated actions and workflows to provide lightning fast support. IrisGPT constantly learns from interactions and feedback."
            imageUrl="/img/homeIlustrationAA1.webp"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "90%"
          />
          <FeatureSectionRight
            title="Highly accurate multilingual answers with citations"
            description="Trained on the world's largest large language models, IrisGPT is the first multilingual and highly accurate answering engine that is trustworthy and will delight your customers."
            imageUrl="/img/homeIlustration5.webp"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "110%"
          />


          <FeatureSection
            title="Omni-channel ticket responder and chatbot that works out of the box"
            description="With omni-channel support, use IrisGPT within your help desk as an auto-responder feature, internal agent assist, or a standalone bot to assist customers"
            imageUrl="/img/homeIlustration1.webp"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "90%"
          />


          <FeatureSectionRight
            title="Start deflecting more than 40% tickets within a matter of days"
            description="Your reliable GPT Chatbot to train agents and be your team's co-pilot during off hours and help train new agents"
            imageUrl="/img/answerFaster.webp"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
          />


      {/* <!-- ======= Testimonials Section ======= --> */}
      <Testimonials />
      {/* <!-- End Testimonials Section --> */}

      {/* <!-- End About Auto-detected incidents Section --> */}
      {/* <!-- ======= Rockstar support team Section ======= --> */}

      {/* <!-- End rockstar support team Section --> */}

      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="light" />
      {/* <!-- End Counts Section --> */}

      <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="How does Agent Assist AI work?"
                  answer="Agent Assist AI, is programmed to work efficiently in understanding and processing customer inquiries in real time. It uses natural language processing and machine learning algorithms to interpret the intent of customer’s issues and provide them with immediate, accurate responses. The core of an Agent Assist Chatbot is to make customer conversations and the process of problem-solving simpler and faster for the support team, thereby enhancing the customer service experience. The Agent Assist Bot operates in collaboration with the company’s human support team. It provides real-time suggestions and support to the agents during the conversation with the customers, embodying the capabilities of a real time agent. These can range from suggesting responses, providing relevant articles, or directing the chat to a human agent when the agent desktop bot can’t process the complexity of the query."
                />
                <Faq
                  id="faq2"
                  question="What type of tasks can Agent Assist AI handle?"
                  answer="The primary role of an Agent Assist AI is to facilitate various tasks that primarily include helping customer service agents by providing them with real-time assistance. The AI Agent Assist can quickly fetch relevant information, reply to customer queries, resolve complaints, troubleshoot, manage multiple customer interactions, and even predict customer needs based on historically collected data. This immensely reduces the response time to solve a customer's problem and enhances the customer's experience. Another noteworthy task handled by Agent Assist Chatbots is their ability to learn and adapt. With each interaction, the chatbot can learn and improve its responses, making it more efficient with time and help lower response time. Support Agent Assist not only enhances the productivity of the customer service team but also significantly improves the overall customer experience and interaction."
                />
                <Faq
                  id="faq3"
                  question="How can Agent Assist AI improve customer satisfaction?"
                  answer="AI Agent Assist is a revolutionary technology that significantly improves the quality of customer service. By using machine learning and natural language processing techniques, the Agent Assist AI can understand the context of customer queries and provide quick, accurate responses. It augments the capabilities of human agents by providing them with instant information and recommended responses, thereby saving agents time, reducing response times and increasing customer satisfaction.

The Agent Assist Chatbot can handle multiple customer interactions simultaneously, ensuring that all customers receive prompt attention. With the potential to operate 24/7, these bots enhance the availability and reach of your business' customer service and agent efficiency. Moreover, an Agent Assist Bot keeps learning from every interaction, thereby continually improving its problem-solving capabilities.

Support Agent Assist is another transformative offering in this space. It aids customer support representatives by providing relevant information during live calls and interactions with virtual agents."
                />
                <Faq
                  id="faq4"
                  question="How long does it take to implement IrisAgent's AI Assist?"
                  answer="Our AI Agent Assist is designed for easy and quick implementation. The exact timeline may vary based on the complexity of your needs and individual business processes. However, most implementations of Agent Assist AI can be completed within days. This includes setting up, integrating, and training the AI with your specific data and requirements. 

An Agent Assist chatbot does not require any coding or technical expertise for installation. Our team will guide you through the process, ensuring the Support Agent Assist chat is fully functional and practical for your organization's needs. "
                />
                <Faq
                  id="faq5"
                  question="How can Agent Assist Chatbot accelerate agent onboarding?"
                  answer="When it comes to accelerating agent onboarding, the agent assist feature of chatbots can be a game changer. It utilizes artificial intelligence to guide new agents through complex processes, helping them to become proficient faster. The agent assist AI provides real-time instruction to contact center agents, ensuring immediate support and preventing costly mistakes. It also functions as an ever-present mentor, enabling new agents to quickly become confident and capable.

An agent assist chatbot takes things a step further by providing 24/7 assistance to your super agents. New agents can interact with the chatbot as if it were a human mentor, asking questions and receiving immediate, accurate responses. Meanwhile, an agent assist bot can help by automating routine tasks, allowing agents to focus on more complex aspects of their role. The combination of these AI tools not only accelerates onboarding but also enhances agent productivity and the overall support agent assist experience."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}



      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
